@import "../core/variables/variables";
@import "../core/variables/app-variables";

.noUi-target {
  background-color: darken($body-bg,5%);
  border: none;
  box-shadow: none;
  border-radius: 1rem;
}

.noUi-horizontal {
  height: 10px;
  padding-right: 1.2rem;
  margin: 0.5rem 0 3.5rem;

  .noUi-handle {
    width: 20px;
    height: 20px;
    top: -5px;
    left: auto;
  }
  .noUi-pips-horizontal {
    left: 7px;
    width: calc(100% - 1rem);
  }
  &.slider-xl {
    height: 14px;
    .noUi-handle {
      width: 28px;
      height: 28px;
      top: -7px;
    }
  }
  &.slider-lg {
    height: 12px;
    .noUi-handle {
      width: 24px;
      height: 24px;
      top: -6px;
    }
  }
  &.slider-sm {
    height: 6px;
    .noUi-handle {
      top: -7px;
    }
  }
  &.slider-xs {
    height: 3px;
    .noUi-handle {
      top: -8px;
    }
  }
}

.noUi-handle {
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  border: 5px solid theme-color("primary") !important;

  &:after,
  &:before {
    display: none;
  }
}

.circle-filled {
  .noUi-handle {
    background: theme-color("primary");
  }
}

.square {
  .noUi-handle {
    background: theme-color("primary");
    border-radius: 3px;

    &:before {
      display: block;
      width: 2px;
      height: 10px;
      left: 2px;
      top: 0;
    }
    &:after {
      display: block;
      width: 2px;
      height: 10px;
      left: 7px;
      top: 0;
    }
  }
  &.slider-xl {
    .noUi-handle {
      &:before {
        left: 5px;
        top: 4px;
      }
      &:after {
        left: 10px;
        top: 4px;
      }
    }
  }
  &.slider-lg {
    .noUi-handle {
      &:before {
        left: 3px;
        top: 2px;
      }
      &:after {
        left: 8px;
        top: 2px;
      }
    }
  }
}

.noUi-connect {
  background: theme-color("primary");
  box-shadow: none;
  border-radius: 1rem;
}

.noUi-vertical {
  display: inline-block;
  width: 8px;
  height: 150px;

  .noUi-handle {
    width: 20px;
    height: 20px;
    top: -5px;
  }
  .noUi-tooltip {
    margin-right: 0.2rem;
  }
  &.square {
    .noUi-handle {
      background: theme-color("primary");
      border-radius: 3px;

      &:before {
        display: block;
        width: 12px;
        height: 2px;
        left: -1px;
        top: 2px;
      }
      &:after {
        display: block;
        width: 12px;
        height: 2px;
        left: -1px;
        top: 7px;
      }
    }
  }
}
